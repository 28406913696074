import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; 
import { ReactTyped } from "react-typed"; 
import showcase from "../assets/showcase.mp4";
import Navbar from "../components/Navbar";

const Hero = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formSubmissionSuccess = queryParams.get("form_submission_success");

  const [showNotification, setShowNotification] = useState(false);
  const [fadeOut, setFadeOut] = useState(false); // State to trigger fade out

  useEffect(() => {
    if (formSubmissionSuccess === "true") {
      setShowNotification(true);
      const timer = setTimeout(() => {
        setFadeOut(true); // Start fade-out animation
        const removeTimer = setTimeout(() => {
          setShowNotification(false);
          setFadeOut(false); // Reset the fade-out state
        }, 1000); // Wait for the fade-out animation to finish before hiding
        return () => clearTimeout(removeTimer);
      }, 4000); // Show notification for 4 seconds before fading out
    }
  }, [formSubmissionSuccess]);

  return (
    <div className="hero">
      <Navbar />
      <div className="absolute bg-black h-screen w-full opacity-60"></div>
      <video loop autoPlay muted>
        <source src={showcase} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      {/* Notification */}
      {showNotification && (
        <div className={`notification ${fadeOut ? "fade-out" : ""}`}>
          <p>Your form has been successfully submitted!</p>
        </div>
      )}

      <div className="hero-content px-4 md:px-0">
        <div className="text-white">
          <div className="max-w-[1200px] w-full h-[400px] mx-auto text-center flex-col justify-center">
            <p className="text-[#00df9a] font-bold text-3xl p-1">
              Speed up Your Climb and
            </p>
            <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">
              Reach higher ranks with Our Personalized Bootcamp
            </h1>
            <div className="flex justify-center items-center">
              <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">
                Become a better
              </p>
              <ReactTyped
                className="md:text-5xl sm:text-4xl text-xl font-bold pl-2 md:pl-4"
                strings={[
                  "Jungler",
                  "Top Laner",
                  "AD Carry",
                  "Mid Laner",
                  "Support",
                ]}
                typeSpeed={120}
                backSpeed={140}
                loop
              />
            </div>
            <div className="mb-8"></div>
            <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 my-6">
              <a
                href="https://calendly.com/sagittarius/60-min-leaguementor"
                className="bg-[#00df9a] rounded-md font-medium text-xl py-3 px-6 text-black text-center"
              >
                Book a 1-on-1 session
              </a>
              <a
                href="#what-we-do"
                className="bg-white text-black rounded-md font-medium text-xl py-3 px-6 text-center mt-4 sm:mt-0"
              >
                Explore our Bootcamp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
