import React from "react";

const LeadGeneration = () => {
  return (
    <div className="w-full py-24 px-6 bg-gray-900 text-white" id="bootcamp">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        {/* Left Section - Consultation */}
        <div className="md:w-1/2 md:pr-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center md:text-left">
            Any questions?
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Ask us anything by making a ticket or just <strong>@</strong> us in #general. You can also book a session this way.
          </p>
          <a
            href="https://discord.gg/fnqWGwakR9" // Change this link as needed
            className="inline-block w-full bg-[#00df9a] text-black font-medium py-4 px-8 rounded-md hover:bg-green-400 transition-colors text-center text-xl"
          >
            Join our Free Discord
          </a>
          <div className="mt-2">
           
          </div>
        </div>

        {/* Divider with "OR" */}
        <div className="hidden md:flex flex-col items-center mx-8">
          <div className="w-px h-64 bg-gray-600 relative">
            <div className="absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-gray-900 px-2 text-sg font-bold">
              or
            </div>
          </div>
        </div>

        {/* Right Section - Purchase Bootcamp */}
        <div className="md:w-1/2 md:pl-8 mt-12 md:mt-0">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-center md:text-left">
            Ready to Jump In?
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Purchase our Bootcamp now and attend the next one!{" "}
            <strong>There's a limited amount of spots!</strong>
          </p>
          <a
            href="/bootcamp-purchase" // Keep or update this link as needed
            className="inline-block w-full bg-[#00df9a] text-black font-medium py-4 px-8 rounded-md hover:bg-green-400 transition-colors text-center text-xl"
          >
            Purchase Bootcamp
          </a>
          <div className="mt-2">
            <p className="md:text-xm">
              More info & FAQ available on the next page
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadGeneration;
