import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

const BootcampPurchase = () => {
  const handleClick = (plan) => {
    window.location.href = `/checkout-page?plan=${plan}`;
  };
  
  const plans = [
    {
      name: 'Basic Plan',
      price: '€19.50 / month',
      vat: '+ VAT',
      features: [
        '1 month access to the Premium Part of the Discord',
        'Ability to attend all Lectures & VOD reviews',
        'Recordings of all past Lectures',
        '100% full refund after the first month if you\'re not satisfied'
      ],
      buttonText: 'Purchase Basic Plan',
      buttonColor: 'bg-[#00df9a] text-black hover:bg-green-400',
      planKey: 'basic',
    },
    {
      name: 'Standard Plan',
      price: '€29.95 / month',
      vat: '+ VAT',
      features: [
        'Everything in the Basic Plan',
        'Guaranteed 1 VOD review',
        'Community-based Training events',
        '10% off on 1-on-1 sessions',
        '100% full refund after the first month if you\'re not satisfied'
      ],
      buttonText: 'Purchase Standard Plan',
      buttonColor: 'bg-[#038039] text-white hover:bg-green-600',
      popular: true,
      planKey: 'standard',
    },
    {
      name: 'Premium Plan',
      price: '€49.50 / month',
      vat: '+ VAT',
      features: [
        'Everything in the Standard Plan',
        'Guaranteed 3 VOD reviews',
        'Coach Support Priority',
        '20% off on private 1-on-1 sessions',
        '100% full refund after the first month if you\'re not satisfied'
      ],
      buttonText: 'Purchase Premium Plan',
      buttonColor: 'bg-[#00df9a] text-black hover:bg-green-400',
      planKey: 'premium',
    },
  ];
  
  return (
    <div className="w-full py-16 px-6 bg-gray-100">
      {/* Bootcamp Plans Section */}
      <section className="max-w-7xl mx-auto mb-12">
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-12">
          Choose Your Bootcamp Plan
        </h1>
        <div className="flex flex-col md:flex-row justify-center items-stretch space-y-8 md:space-y-0 md:space-x-8">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`bg-white shadow-lg rounded-lg overflow-hidden flex-1 relative ${
                plan.popular ? 'transform md:scale-105 shadow-xl' : ''
              }`}
            >
              {/* Most Popular Badge */}
              {plan.popular && (
                <div className="absolute top-0 right-0 bg-[#00df9a] text-black px-4 py-2 font-bold uppercase text-sm rounded-bl-lg">
                  Most Popular
                </div>
              )}
              <div className="p-8">
                <h2 className="text-2xl font-bold mb-4">{plan.name}</h2>
                <p className="text-xl font-semibold mb-1">{plan.price}</p>
                <p className="text-sm text-gray-500 mb-6">{plan.vat}</p>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-start">
                      <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleClick(plan.planKey)}
                  className={`w-full ${plan.buttonColor} font-medium py-3 px-8 rounded-md transition-colors`}
                >
                  {plan.buttonText}
                </button>
              </div>
            </div>
          ))}
        </div>
        {/* VAT and Refund Policy Note */}
        <div className="text-center mt-12">
          <p className="text-sm text-gray-700">
            All prices are exclusive of VAT. VAT will be calculated at checkout based on your location.
          </p>
          <p className="text-sm text-gray-700 mt-2">
            We offer a <strong>100% full refund</strong> after the first month if you're not satisfied with the bootcamp.
          </p>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="max-w-5xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mt-2 mb-12">
          Frequently Asked Questions
        </h2>
        <div className="space-y-8">
          {/* FAQ Item 1 */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What happens after I Buy?
            </h3>
            <p>
              We'll email you a code that when used will give you a premium role in our <a href="https://discord.gg/fnqWGwakR9" className="text-blue-500">Discord Server</a>, where you will see when the next coachings are.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What do you mean by Guaranteed 1 VOD review?
            </h3>
            <p>
              We host weekly VOD review events on our Discord, and we use a <i>first-come first-serve priority system. However to make things fair we will review at least 1 of your replays even if you don't make it to the live events.</i> So, you can theoretically get more reviews if you're active in the community.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What is your refund policy?
            </h3>
            <p>
              We stand by the quality of our bootcamp. If you're not satisfied after the first month, we offer a 100% full refund. Just contact our support team in the <a href="https://discord.gg/fnqWGwakR9" className="text-blue-500">Discord Server</a> or at <a href="mailto:support@leaguementor.com" className="text-blue-500">support@leaguementor.com</a>, and we'll process your refund promptly.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What role do you guys focus on?
            </h3>
            <p>
              You can attend every event, however we like to separate students by their roles. so you can attend jungle focused VOD events and so on.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What happens in the weekly VOD reviews?
            </h3>
            <p>
              Students send coaches their League of legends game replays or video recordings and the coaches give you custom tailored advice that will help you climb and get better.
            </p>
          </div>
          {/* FAQ Item 3 */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              Can I upgrade my plan later?
            </h3>
            <p>
              Yes, you can upgrade your plan at any time. Simply contact our support team in the <a className="text-blue-500" href="https://discord.gg/fnqWGwakR9">Discord Server</a> or on <a className="text-blue-500" href="mailto:support@leaguementor.com">support@leaguementor.com</a>, and they will assist you with the process.
            </p>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-2">
              What happens in training events?
            </h3>
            <p>
              Our coaches create custom events like matchup training, where they pair up midlane players in certain matchups. It's all fueled by the community and what they struggle with.
            </p>
          </div>
          
          {/* Add more FAQ items as needed */}
        </div>
      </section>
    </div>
  );
};

export default BootcampPurchase;
