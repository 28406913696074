import { useEffect } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import TrustSection from "./components/TrustSection";
import LeadGeneration from "./components/LeadGeneration";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Review from "./components/Review";
import WhatWeDo from "./components/WhatWeDo";
import Premium from "./components/Premium";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Conversion from "./components/Conversion";
import BootcampPurchase from './components/BootcampPurchase';
import Navbar from './components/Navbar';
import NavbarUniversal from './components/NavbarUniversal';
import CheckoutPage from './components/CheckoutPage';
import DiscordLecture from './components/DiscordLecture';
import OneOnOneBooking from './components/OneOnOneBooking'
import { FeedbackFish } from '@feedback-fish/react';
// Importing BrowserRouter, Routes, and Route
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-HX93WGTLEN");

    // Track pageviews on route change
    const trackPageView = () => {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      // For Google Ads
      window.gtag('config', 'AW-16542485881', { 'page_path': window.location.pathname });
    };

    // Call trackPageView when the location changes
    trackPageView();
  }, [location]); // Re-run effect when location changes

  return (
    <Router>
      <div className="">
        <Routes>
          {/* Home route */}
          <Route path="/" element={<HomePage />} />

          {/* Terms and Conditions route */}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          {/* Privacy Policy route */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Conversion route */}
          <Route path="/conversion" element={<Conversion />} /> {/* Add the conversion route */}

          <Route path="/bootcamp-purchase" element={<PurchaseBootcampPage />} /> {/* Add the new bootcamp purchase route */}

          <Route path="/checkout-page" element={<CheckoutPage />} />

          <Route path="/scheduling" element={<ScheduleCoachingPage />} />

          <Route path="/discord-lecture" element={<DiscordLectureConversionPage />} />

        </Routes>
        <FeedbackFish projectId="fdec0a0830e100">
  <button style={{ position: 'fixed', bottom: '20px', right: '20px', backgroundColor: '#00df9a', color: 'black', border: 'none', borderRadius: '5%', width: '170px', height: '40px', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
    Leave us Feedback!
  </button>
</FeedbackFish>

        {/* Footer remains outside Routes */}
        <Footer />
      </div>
    </Router>
  );
}

// Create a HomePage component to wrap all your home content

const SuccessfulConversion = () => {
  return (
    <>
      <Navbar />
      <BootcampPurchase />
    </>
  );
};

const DiscordLectureConversionPage = () => {
  return (
    <>
      <NavbarUniversal />
      <DiscordLecture />
    </>
  );
};

const PurchaseBootcampPage = () => {
  return (
    <>
      <NavbarUniversal />
      <BootcampPurchase />
    </>
  );
};

const ScheduleCoachingPage = () => {
  return (
    <>
      <NavbarUniversal />
      <OneOnOneBooking />
    </>
  );
};

const HomePage = () => {
  return (
    <>
      <Hero />
      <TrustSection />
      <WhatWeDo />
      <Review />
      <LeadGeneration />
    </>
  );
};

export default App;
