import React from "react";

const OneOnOneBooking = () => {
  return (
    <div className="text-gray-200 pt-8 bg-white" id="coaching-session">
      <div className="w-full py-16">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
          <div className="mb-10 space-y-4 px-6 md:px-0">
            <h2 className="text-center text-black text-2xl font-bold md:text-4xl">
              Schedule Your 1-on-1 Private Coaching Session
            </h2>
            <p className="text-center text-black">
              Get personalized guidance to elevate your game. Choose a time, share your goals, and let us help you improve.
            </p>
          </div>

          <div className="max-w-3xl mx-auto bg-white p-8 border rounded-3xl shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out">
            {/* Placeholder for Custom Logic */}
            <div className="border-2 border-dashed border-gray-400 h-64 flex items-center justify-center">
              {/* Your custom logic or form will go here */}
              <p className="text-gray-600 font-light italic">
                [ Your custom scheduling logic / form goes here ]
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneOnOneBooking;
